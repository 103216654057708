/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
        $(window).on("load", function () {
          AOS.refresh();
        });
        AOS.init({
          duration: 1200,
          disable: "mobile",
        });
        var num = 125; //number of pixels before modifying styles
        $(window).bind("scroll", function () {
          if ($(window).scrollTop() > num) {
            $("body").addClass("body-scrolled");
          } else {
            $("body").removeClass("body-scrolled");
          }
        });

        $(".menu-utility .menu-open").click(function () {
          $("#menu-mobile .menu-item").toggleClass("slider-list");
          $("#menu-mobile .menu-item").removeClass("hide-list");
        });

        $(".menu-side .bar-container").click(function () {
          $(".dropdown-menu").addClass("slider-dropdown");
          $(".dropdown-menu").removeClass("hide-dropdown");
          $("#menu-dropdown .menu-item").addClass("slider-list");
          $("#menu-dropdown .menu-item").removeClass("hide-list");
          $(".hr_footer").addClass("show-footer");
          $(".dropdown-menu .path img").addClass("animated");
        });
        $(".dropdown-menu .close").click(function () {
          $(".dropdown-menu").addClass("hide-dropdown");
          $("#menu-dropdown .menu-item").addClass("hide-list");
          $("#menu-dropdown .menu-item").removeClass("slider-list");
          $(".hr_footer").removeClass("show-footer");
          $(".dropdown-menu .path img").removeClass("animated");
        });

        $(".sub-arrow").click(function () {
          $(this).find("i").toggleClass("turn-arrow");
          $(this).siblings(".sub-pages").toggleClass("tap");
        });

        // SLICK HEROES
        $(".js-slick-heroes").slick({
          dots: true,
          infinite: true,
          arrows: false,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          fade: true,
          draggable: false,
          slidesToShow: 1,
          adaptiveHeight: true,
          pauseOnHover: true,
          customPaging: function (slider, i) {
            // var title = $(slider.$slides[i]).data('title');
            return "";
          },
          responsive: [
            {
              breakpoint: 500,
              settings: {
                dots: true,
                arrows: false,
              },
            },
          ],
        });

        if ($(".banner").hasClass("foundslide")) {
          $(".noslider").remove();
        } else if ($(".banner").hasClass("noslider")) {
          $(".js-slick-heroes").slick("unslick");
        }

        $(".js-loadmore-posts").click(function () {
          var button = $(this),
            data = {
              action: "loadmore",
              query: loadmore_posts_params.posts, // that's how we get params from wp_localize_script() function
              page: loadmore_posts_params.current_page,
            };

          $.ajax({
            url: loadmore_posts_params.ajaxurl, // AJAX handler
            data: data,
            type: "POST",
            beforeSend: function (xhr) {
              button.html('<span class="button primary">Loading...</span>'); // change the button text, you can also add a preloader image
            },
            success: function (data) {
              if (data) {
                button
                  .html('<span class="button primary">More posts</span>')
                  .before(data); // insert new posts
                loadmore_posts_params.current_page++;

                if (
                  loadmore_posts_params.current_page ===
                  loadmore_posts_params.max_page
                ) {
                  button.remove(); // if last page, remove the button
                }

                // you can also fire the "post-load" event here if you use a plugin that requires it
                // $( document.body ).trigger( 'post-load' );
              } else {
                button.remove(); // if no data, remove the button as well
              }
            },
          });
        });

        $(".js-select2").select2({
          minimumResultsForSearch: Infinity,
        });

        $(".js-select2-search").select2();

        $(".js-selector-with-url").change(function () {
          location.href = $(this).val();
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        var $body = $("body");

        //Toggle Filter Options
        $(".js-filter-expand").click(function () {
          $(".js-filter-expander").toggleClass("expanded");
        });

        //Toggle menu
        $(".js-menu-open").click(function () {
          $("body").toggleClass("menu-off-canvas-active");
          $body.removeClass("fb-messenger-active");
          $body.removeClass("popup-active-feedback");
        });

        $(".js-menu-open-left").click(function () {
          $body.toggleClass("menu-off-canvas-active");
          $body.toggleClass("left");
          $body.removeClass("fb-messenger-active");
          $body.removeClass("popup-active-feedback");
        });

        //Toggle Feedback
        $(".js-popup-feedback").click(function () {
          $body.toggleClass("popup-active-feedback");
          $body.removeClass("fb-messenger-active");
        });

        //Toggle FB Messenger
        $(".js-fb-messenger").click(function () {
          $body.toggleClass("fb-messenger-active");
          $body.removeClass("menu-off-canvas-active");
        });

        //Toggle Search
        $(".js-popup-search").click(function (e) {
          e.preventDefault();
          $(".popup-wrap-search input").focus();
          $body.toggleClass("popup-active-search");
        });

        $(document).keyup(function (e) {
          if (e.keyCode === 27) {
            // escape key maps to keycode `27`
            $body.removeClass(
              "body-is-scrolled menu-off-canvas-active popup-active-search fb-messenger-active popup-active-feedback left"
            );
          }
        });

        //Toggle accordion links
        $(document).on("click", ".js-accordion-title", function (e) {
          e.preventDefault();
          $(this)
            .closest(".js-accordion")
            .toggleClass("expanded")
            .siblings()
            .removeClass("expanded");
        });

        $(".js-popup-feedback-form").submit(function (e) {
          e.preventDefault();

          var $this = $(this);
          var $message = $this.find(".js-message");

          $.ajax({
            type: "get",
            url: templateJS.ajaxURL,
            dataType: "json",
            data: {
              action: "popup_feedback_submit",
              data: $this.serialize(),
            },
            success: function (r) {
              if (r.success) {
                $this.find("input,textarea,button").prop("disabled", true);
                $message.text(
                  "Successful. This popup will automatically close in 5 seconds."
                );
                setTimeout(function () {
                  $body.toggleClass("popup-active-feedback");
                  $this.find("input,textarea,button").prop("disabled", false);
                  $this.find("input,textarea").val("");
                  $message.text(
                    "Please fill out the form below to leave feedback."
                  );
                }, 5000);
              } else {
                $message.hide("slow", function () {
                  $message.text(r.data.message);
                  $message.show("slow");
                });
              }
            },
          });
        });
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page

        $(".js-slider-for").slick({
          dots: true,
          infinite: true,
          arrows: false,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          fade: true,
          draggable: false,
          adaptiveHeight: true,
          pauseOnHover: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          //prevArrow:'<span class="slick-prev"><i class="fa fa-angle-left"></i></span>',
          //nextArrow:'<span class="slick-next"><i class="fa fa-angle-right"></i></span>',
          asNavFor: $(".js-slider-nav, .js-slider-bottom"),
          customPaging: function (slider, i) {
            // return "<a>" + i + "</a><hr/><a>" + (i + 1) + "</a>";
          },
        });

        $(".js-slider-nav").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          asNavFor: $(".js-slider-bottom, .js-slider-for"),
          dots: false,
          arrows: false,
          centerMode: true,
          draggable: true,
          focusOnSelect: true,
          adaptiveHeight: true,
        });

        $(".js-slider-bottom").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          asNavFor: $(".js-slider-nav, .js-slider-for"),
          arrows: true,
          prevArrow:
            '<span class="slick-prev"><i class="fa fa-angle-left"></i></span>',
          nextArrow:
            '<span class="slick-next"><i class="fa fa-angle-right"></i></span>',
          centerMode: true,
          draggable: true,
          focusOnSelect: true,
          adaptiveHeight: true,
          infinite: true,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                arrows: true,
              },
            },
            {
              breakpoint: 750,
              settings: {
                arrows: false,
              },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ],
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    overview: {
      init: function () {
        // JavaScript to be fired on the about us page

        $(".js-slider-for-history").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          prevArrow:
            '<span class="slick-prev"><i class="fa fa-angle-left"></i></span>',
          nextArrow:
            '<span class="slick-next"><i class="fa fa-angle-right"></i></span>',
          fade: true,
          asNavFor: ".js-slider-nav-history",
          adaptiveHeight: true,
        });

        $(".js-slider-nav-history").slick({
          slidesToShow: 7,
          slidesToScroll: 1,
          asNavFor: ".js-slider-for-history",
          dots: false,
          arrows: false,
          centerMode: true,
          draggable: true,
          focusOnSelect: true,
          adaptiveHeight: true,
          // infinite: false,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 7,
              },
            },
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 5,
              },
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
              },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ],
        });
      },
    },
    // About us page, note the change from about-us to about_us.
    single_attraction: {
      init: function () {
         $(".media-gallery-wrap").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 500,
          autoplay: false,
          // autoplaySpeed: 5000,
          easing: "swing",
          // dots: false,
          // arrows: false,
          prevArrow:
            '<span class="slick-arrow-direction slick-prev"><i class="fa fa-angle-left"></i></span>',
          nextArrow:
            '<span class="slick-arrow-direction slick-next"><i class="fa fa-angle-right"></i></span>',
          centerMode: false,
          draggable: true,
          focusOnSelect: true,
          adaptiveHeight: true,
          pauseOnHover: true,
          infinite: false,
          dots: false,
          mobileFirst: true,
        });
        $(".js-slick-testimonials").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          easing: "swing",
          // dots: false,
          // arrows: false,
          prevArrow:
            '<span class="slick-arrow-direction slick-prev"><i class="fa fa-angle-left"></i></span>',
          nextArrow:
            '<span class="slick-arrow-direction slick-next"><i class="fa fa-angle-right"></i></span>',
          centerMode: false,
          draggable: true,
          focusOnSelect: true,
          adaptiveHeight: true,
          pauseOnHover: true,
          infinite: false,
          dots: false,
          mobileFirst: true,
        });
        valuetitle = $(".current-page-title").html();
        $("#input_4_5").val(valuetitle);
        $("#input_4_5").attr("value", valuetitle);

        $totals = $(".media-gallery-wrap.js-total-count").attr("data-total");
        if ($totals <= 4) {
          $("#prev_button").css("display", "none");
          $("#next_button").css("display", "none");
        }
        $("#prev_button").click(function () {
          $(".media-gallery-wrap").removeClass("slideleft");
        });
        $("#next_button").click(function () {
          $(".media-gallery-wrap").addClass("slideleft");
        });
        // JavaScript to be fired on the about us page
        
        var gmap_id = $(".gmapcontact"),
          zoom = gmap_id.data("zoom") || 18,
          address = gmap_id.data("address") || "NL, Canada",
          lat = gmap_id.data("lat"),
          long = gmap_id.data("long"),
          geocoder = new google.maps.Geocoder();
          geocoder.geocode({ address: address }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              var centerLL = results[0].geometry.location;
              var map = new google.maps.Map(gmap_id.get(0), {
                center: centerLL,
                scrollwheel: false,
                zoom: zoom || 18,
              });

              new google.maps.Marker({
                map: map,
                position: centerLL,
                title: results[0].formatted_address,
              });
            } else {
              console.log(
                "Geocode was not successful for the following reason: " + status
              );
              $(".gmapcontact").html(
                '<div class="blurb no-results"><h3>Google Maps is not available due to unavailable location data.</h3></div>'
              );
            }
          });
      },
    },
    search_availability: {
      init: function () {
        $(".js-slick-testimonials").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          easing: "swing",
          // dots: false,
          // arrows: false,
          prevArrow:
            '<span class="slick-arrow-direction slick-prev"><i class="fa fa-angle-left"></i></span>',
          nextArrow:
            '<span class="slick-arrow-direction slick-next"><i class="fa fa-angle-right"></i></span>',
          centerMode: false,
          draggable: true,
          focusOnSelect: true,
          adaptiveHeight: true,
          pauseOnHover: true,
          infinite: false,
          dots: false,
          mobileFirst: true,
        });
        valuetitle = "Sanger Memorial RV Park";
        $("#input_4_5").val(valuetitle);
        $("#input_4_5").attr("value", valuetitle);

        $totals = $(".media-gallery-wrap.js-total-count").attr("data-total");
        if ($totals <= 4) {
          $("#prev_button").css("display", "none");
          $("#next_button").css("display", "none");
        }
        $("#prev_button").click(function () {
          $(".media-gallery-wrap").removeClass("slideleft");
        });
        $("#next_button").click(function () {
          $(".media-gallery-wrap").addClass("slideleft");
        });
        $(document).click(function() {
          $(".mphb-datepick").parent().removeClass("active-input");
        });
        $(".mphb-datepick").click(function (event) {
          $(this).parent().addClass("active-input");
          event.stopPropagation();
        });

        var gmap_id = $(".gmapcontact"),
          zoom = gmap_id.data("zoom") || 18,
          address = gmap_id.data("address") || "NL, Canada",
          lat = gmap_id.data("lat"),
          long = gmap_id.data("long"),
          geocoder = new google.maps.Geocoder();
          geocoder.geocode({ address: address }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              var centerLL = results[0].geometry.location;
              var map = new google.maps.Map(gmap_id.get(0), {
                center: centerLL,
                scrollwheel: false,
                zoom: zoom || 18,
              });

              new google.maps.Marker({
                map: map,
                position: centerLL,
                title: results[0].formatted_address,
              });
            } else {
              console.log(
                "Geocode was not successful for the following reason: " + status
              );
              $(".gmapcontact").html(
                '<div class="blurb no-results"><h3>Google Maps is not available due to unavailable location data.</h3></div>'
              );
            }
          });

        // JavaScript to be fired on the about us page
      },
    },
    contact: {
      init: function () {
        // JavaScript to be fired on the contact page
        var gmap_id = $(".gmapcontact"),
          zoom = gmap_id.data("zoom") || 18,
          address = gmap_id.data("address") || "NL, Canada",
          geocoder = new google.maps.Geocoder();

        geocoder.geocode({ address: address }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            var centerLL = results[0].geometry.location;

            var map = new google.maps.Map(gmap_id.get(0), {
              center: centerLL,
              scrollwheel: false,
              zoom: zoom || 18,
            });
            new google.maps.Marker({
              map: map,
              position: centerLL,
              title: results[0].formatted_address,
            });
          } else {
            console.log(
              "Geocode was not successful for the following reason: " + status
            );
            $(".gmapcontact").html(
              '<div class="blurb no-results"><h3>Google Maps is not available due to unavailable location data.</h3></div>'
            );
          }
        });
      },
    },
    search_results: {
      init: function() {
        function magnify(imgID, zoom) {
          var img, glass, w, h, bw;
          img = document.getElementById(imgID);
          /*create magnifier glass:*/
          glass = document.createElement("DIV");
          glass.setAttribute("class", "img-magnifier-glass");
          /*insert magnifier glass:*/
          img.parentElement.insertBefore(glass, img);
          /*set background properties for the magnifier glass:*/
          glass.style.backgroundImage = "url('" + img.src + "')";
          glass.style.backgroundRepeat = "no-repeat";
          glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
          bw = 3;
          w = glass.offsetWidth / 2;
          h = glass.offsetHeight / 2;
          /*execute a function when someone moves the magnifier glass over the image:*/
          glass.addEventListener("mousemove", moveMagnifier);
          img.addEventListener("mousemove", moveMagnifier);
          /*and also for touch screens:*/
          glass.addEventListener("touchmove", moveMagnifier);
          img.addEventListener("touchmove", moveMagnifier);
          function moveMagnifier(e) {
            var pos, x, y;
            /*prevent any other actions that may occur when moving over the image*/
            e.preventDefault();
            /*get the cursor's x and y positions:*/
            pos = getCursorPos(e);
            x = pos.x;
            y = pos.y;
            /*prevent the magnifier glass from being positioned outside the image:*/
            if (x > img.width - (w / zoom)) {x = img.width - (w / zoom);}
            if (x < w / zoom) {x = w / zoom;}
            if (y > img.height - (h / zoom)) {y = img.height - (h / zoom);}
            if (y < h / zoom) {y = h / zoom;}
            /*set the position of the magnifier glass:*/
            glass.style.left = (x - w) + "px";
            glass.style.top = (y - h) + "px";
            /*display what the magnifier glass "sees":*/
            glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
          }
          function getCursorPos(e) {
            var a, x = 0, y = 0;
            e = e || window.event;
            /*get the x and y positions of the image:*/
            a = img.getBoundingClientRect();
            /*calculate the cursor's x and y coordinates, relative to the image:*/
            x = e.pageX - a.left;
            y = e.pageY - a.top;
            /*consider any page scrolling:*/
            x = x - window.pageXOffset;
            y = y - window.pageYOffset;
            return {x : x, y : y};
          }
        }
        magnify("myimage-search", 3);
      },
      finalize: function () {
      }
    },
    booking_confirmation: {
      init: function() {
          var txt1 = "<div style='margin-bottom: 0.4em;'><strong style='font-weight: 600; font-size: 1.1em;'>Traveling with Pets? </strong>| <a target='_blank' style='font-weight: 700; font-size: 1.1em;' href='http://erma.jac.digital/pet-policy/'>View Pet Policy</a></div>"; 
          var txt2 = "<div style='margin-bottom: 0.4em;'><strong style='font-weight: 600; font-size: 1.1em;'>Equipment Selector</strong><span class='services-error-message'>| Please select one of the options below</span></div>"; 
          var txt3 = "<div style='margin-bottom: 0.4em;'><strong style='font-weight: 600; font-size: 1.1em;'>Slide Out(s)</strong></div>"; 
          $(".mphb_checkout-services-list li:first-child label").prepend(txt1);   // Append new elements
          $(".mphb_checkout-services-list li:nth-child(2) label").prepend(txt2);   // Append new elements
          $(".mphb_checkout-services-list li:nth-child(9) label").prepend(txt3);   // Append new elements
          $(".mphb-price-breakdown-adults").css('display','none');   // Append new elements
          $(".mphb-price-breakdown-children").css('display','none');   // Append new elements
          
          $("#mphb_room_details-0-service-782-id").prop("required", true);
          $("#mphb_room_details-0-service-783-id").prop("required", true);
          $("#mphb_room_details-0-service-955-id").prop("required", true);
          $("#mphb_room_details-0-service-956-id").prop("required", true);
          $("#mphb_room_details-0-service-957-id").prop("required", true);
          $("#mphb_room_details-0-service-958-id").prop("required", true);
          $("#mphb_room_details-0-service-959-id").prop("required", true);
          $("#mphb_room_details-0-service-960-id").prop("required", true);
          $("#mphb_room_details-0-service-961-id").prop("required", true);

        $(".mphb_sc_checkout-submit-wrapper .button").click(function () {
          if ($("#mphb_room_details-0-service-955-id").prop("checked") || $("#mphb_room_details-0-service-956-id").prop("checked") || $("#mphb_room_details-0-service-957-id").prop("checked") || $("#mphb_room_details-0-service-958-id").prop("checked") || $("#mphb_room_details-0-service-959-id").prop("checked") || $("#mphb_room_details-0-service-960-id").prop("checked") || $("#mphb_room_details-0-service-961-id").prop("checked")) {
            $("#mphb_room_details-0-service-955-id").prop("required", false);
            $("#mphb_room_details-0-service-956-id").prop("required", false);
            $("#mphb_room_details-0-service-957-id").prop("required", false);
            $("#mphb_room_details-0-service-958-id").prop("required", false);
            $("#mphb_room_details-0-service-959-id").prop("required", false);
            $("#mphb_room_details-0-service-960-id").prop("required", false);
            $("#mphb_room_details-0-service-961-id").prop("required", false);
            $(".services-error-message").css('color', 'black');
          } else {
            $("#mphb_room_details-0-service-955-id").prop("required", true);
            $("#mphb_room_details-0-service-956-id").prop("required", true);
            $("#mphb_room_details-0-service-957-id").prop("required", true);
            $("#mphb_room_details-0-service-958-id").prop("required", true);
            $("#mphb_room_details-0-service-959-id").prop("required", true);
            $("#mphb_room_details-0-service-960-id").prop("required", true);
            $("#mphb_room_details-0-service-961-id").prop("required", true);
            $(".services-error-message").css('color', 'red');
          }

          if ($("#mphb_room_details-0-service-962-id").prop("checked") || $("#mphb_room_details-0-service-963-id").prop("checked")) {
            $("#mphb_room_details-0-service-962-id").prop("required", false);
            $("#mphb_room_details-0-service-963-id").prop("required", false);
          } else {
            $("#mphb_room_details-0-service-962-id").prop("required", true);
            $("#mphb_room_details-0-service-963-id").prop("required", true);
          }
        });
              
      },
      finalize: function () {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
